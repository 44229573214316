import {useEffect } from "react";
import Loading from ".//views/loading";
import { Admin, CustomRoutes, useSafeSetState, Authenticated, Resource } from 'react-admin';
import { Route } from "react-router-dom";
import { WerifyLayout } from './views/layout';
import { Dashboard } from './views/dashboard';
import Login from './views/login';
import WerifyTheme from './theme';
import { WerifyPointKiosk } from "./views/werify_point_kiosk";
import { VidChainRedirectLogin } from "./views/vidchain_redirect_login";
import { VidChainRedirectVerifier } from "./views/vidchain_redirect_verifier";
import Graphiql from "./views/graphiql";
import authProvider from "./lib/auth_provider";
import { defaultDataProvider } from "./lib/data_provider";
import { OneTimeLogin, OneTimeLoginList, OneTimeLoginShow } from "./views/one_time_login";
import {
  PermIdentity, DesignServices, Create, TextSnippet, AccountCircle, Inventory, BarChart, Inventory2Outlined,
  DriveFileMove, AlternateEmail, CardMembership, VpnKey, Hub, ConnectedTv,
} from '@mui/icons-material';
import { PersonList, PersonShow } from "./views/person";
import { KnownDidList, KnownDidShow, KnownDidCreate, KnownDidEdit } from "./views/known_did";
import { KnownAttributeList, KnownAttributeShow, KnownAttributeCreate, KnownAttributeEdit } from "./views/known_attribute";
import { KnownCredentialTypeList, KnownCredentialTypeShow, KnownCredentialTypeCreate, KnownCredentialTypeEdit } from "./views/known_credential_type";
import { EmailList, EmailShow } from "./views/email_address";
import { TermsAcceptanceList, TermsAcceptanceShow } from "./views/terms_acceptance";
import ResourceLayout, { ResourceLayoutWrite } from "./views/admin_layout";
import { WerifyPointList, WerifyPointShow } from "./views/werify_point";
import { RuleList, RuleShow } from "./views/rule";
import { AttemptList, AttemptShow } from "./views/attempt";
import { SessionList, SessionShow } from "./views/session";
import WerifyPointWizard from "./views/wizard_werify_point";
import RuleWizardCreate from "./views/wizard_rule/index_create";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { messages, browserLocale, LOCALES } from "./i18n";
import _ from 'lodash';
import { AccountStateEdit } from "./views/account_state";
import Lottery from "./views/lottery";
import { DidListCreate, DidListList, DidListShow } from "./views/did_list";
import RuleWizardEdit from "./views/wizard_rule/index_edit";
import Statistics from "./views/statistics";
import WerifyPointFavorite from "./views/werify_point_favorite";
import { AttributeListCreate, AttributeListList, AttributeListShow } from "./views/attribute_list";
import Help from "./views/help";


function App() {
  const [dataProvider, setDataProvider] = useSafeSetState<any>(null);
  const [databaseLocale, setDatabaseLocale] = useSafeSetState(null);
  const [reloadLang, setReloadLang] = useSafeSetState(null);

  useEffect(() => {
    async function initApp() {
      const dataProv = await defaultDataProvider();
      setDataProvider(dataProv);
      const lang = await authProvider.getLang();
      setDatabaseLocale(_.toLower(lang));
    }
    initApp();
  }, [databaseLocale, reloadLang]);

  const finalLocale = _.includes(LOCALES, databaseLocale) ? databaseLocale : browserLocale;
  const i18nProvider =  polyglotI18nProvider(locale => messages[locale], finalLocale);

  if (!dataProvider || !i18nProvider) { return <Loading /> };

  return (
    <Admin
      disableTelemetry
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      theme={WerifyTheme}
      layout={WerifyLayout}
     >
        <CustomRoutes noLayout >
          <Route path="/graphiql" element={<Authenticated><Graphiql /></Authenticated>} />
          <Route path="/werify_point_kiosk/:access_token" element={<WerifyPointKiosk />} />
          <Route path="/werify_point_kiosk/:access_token/:user_token" element={<WerifyPointKiosk />} />
          <Route path="/vid_chain/login_redirect_uri" element={<VidChainRedirectLogin setReloadLang={setReloadLang} />} />
          <Route path="/vid_chain/verifier_redirect_uri" element={<VidChainRedirectVerifier setReloadLang={setReloadLang} />} />
          <Route path="/one_time_login/:access_token" element={<OneTimeLogin setReloadLang={setReloadLang} />} />
          <Route path="/create_werify_point" element={<WerifyPointWizard />} />
          <Route path="/create_rule" element={<RuleWizardCreate />} />
          <Route path="/Rule/:id" element={<RuleWizardEdit />} />
          <Route path="/lottery" element={<Lottery />} />
          <Route path="/help" element={<Help />} />
        </CustomRoutes>
        <CustomRoutes>
          <Route path="/favorites" element={<WerifyPointFavorite />} />
        </CustomRoutes>
        <Resource
          name="Statistic"
          list={<ResourceLayout><Statistics /></ResourceLayout>}
          icon={BarChart}
        />
        <Resource
          name="WerifyPoint"
          list={<ResourceLayout><WerifyPointList /></ResourceLayout>}
          show={<ResourceLayout><WerifyPointShow /></ResourceLayout>}
          icon={Hub}
        />
        <Resource
          name="Rule"
          list={<ResourceLayout><RuleList /></ResourceLayout>}
          show={<ResourceLayout><RuleShow /></ResourceLayout>}
          icon={DesignServices}
        />
        <Resource
          name="Attempt"
          list={<ResourceLayout><AttemptList /></ResourceLayout>}
          show={<ResourceLayout><AttemptShow /></ResourceLayout>}
          icon={DriveFileMove}
        />
        <Resource
          name="KnownDid"
          list={<ResourceLayout><KnownDidList /></ResourceLayout>}
          show={<ResourceLayout><KnownDidShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><KnownDidCreate /></ResourceLayoutWrite>}
          edit={<ResourceLayoutWrite><KnownDidEdit /></ResourceLayoutWrite>}
          icon={TextSnippet}
        />
        <Resource
          name="KnownCredentialType"
          list={<ResourceLayout><KnownCredentialTypeList /></ResourceLayout>}
          show={<ResourceLayout><KnownCredentialTypeShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><KnownCredentialTypeCreate /></ResourceLayoutWrite>}
          edit={<ResourceLayoutWrite><KnownCredentialTypeEdit /></ResourceLayoutWrite>}
          icon={CardMembership}
        />
        <Resource
          name="KnownAttribute"
          list={<ResourceLayout><KnownAttributeList /></ResourceLayout>}
          show={<ResourceLayout><KnownAttributeShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><KnownAttributeCreate /></ResourceLayoutWrite>}
          edit={<ResourceLayoutWrite><KnownAttributeEdit /></ResourceLayoutWrite>}
          icon={AccountCircle}
        />
        <Resource
          name="DidList"
          list={<ResourceLayout><DidListList /></ResourceLayout>}
          show={<ResourceLayout><DidListShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><DidListCreate /></ResourceLayoutWrite>}
          icon={Inventory}
        />
        <Resource
          name="AttributeList"
          list={<ResourceLayout><AttributeListList /></ResourceLayout>}
          show={<ResourceLayout><AttributeListShow /></ResourceLayout>}
          create={<ResourceLayoutWrite><AttributeListCreate /></ResourceLayoutWrite>}
          icon={Inventory2Outlined}
        />
        <Resource
          name="AccountState"
          edit={<ResourceLayoutWrite><AccountStateEdit /></ResourceLayoutWrite>}
        />
        <Resource
          name="Person"
          list={<ResourceLayout><PersonList /></ResourceLayout>}
          show={<ResourceLayout><PersonShow /></ResourceLayout>}
          icon={PermIdentity}
        />
        <Resource
          name="EmailAddress"
          list={<ResourceLayout><EmailList /></ResourceLayout>}
          show={<ResourceLayout><EmailShow /></ResourceLayout>}
          icon={AlternateEmail}
        />
        <Resource
          name="TermsAcceptance"
          list={<ResourceLayout><TermsAcceptanceList /></ResourceLayout>}
          show={<ResourceLayout><TermsAcceptanceShow /></ResourceLayout>}
          icon={Create}
        />
        <Resource
          name="Session"
          list={<ResourceLayout><SessionList /></ResourceLayout>}
          show={<ResourceLayout><SessionShow /></ResourceLayout>}
          icon={ConnectedTv}
        />
        <Resource
          name="OneTimeLogin"
          list={<ResourceLayout><OneTimeLoginList /></ResourceLayout>}
          show={<ResourceLayout><OneTimeLoginShow /></ResourceLayout>}
          icon={VpnKey}
        />
    </Admin>
  );
}

export default App;
