import { useEffect } from 'react';
import { Box, Container, Button } from '@mui/material';
import {
  AutocompleteInput, FunctionField, ReferenceField, ReferenceInput, useSafeSetState, DateField,
  required, TopToolbar , Form, useTranslate, TextInput, List, TextField, Datagrid, Pagination
} from 'react-admin';
import { WerifyLayout } from './layout';
import requirePermissions from '../lib/permissions';
import { NoPermissionsLayout } from './no_permissions';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import Loading from './loading';
import _ from "lodash";
import { FileDownload } from '@mui/icons-material';
import { defaultDataProvider } from '../lib/data_provider';


const Lottery = () => {
  const translate = useTranslate();
  const [filter, setFilter] = useSafeSetState({});
  const [storedValue, setStoredValue] = useSafeSetState("");
  const [permissions, setPermissions] = useSafeSetState("");
  const [loading, setLoading] = useSafeSetState(true);

  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions, setLoading}) }, []);

  if (loading) return <Loading />;
  if (!requirePermissions.canReadAdminResources(permissions)) { return <NoPermissionsLayout /> };

  const handleSubmit = async (values) => {
    if (!values) return;
    setFilter({werifyPointIdEq: values.werifyPointId, stateEq: "APPROVED"});
    setStoredValue(values.storedValue);
  }

  const getAttempts = async (dataProvider, perPage) => {
    return await dataProvider.getList('Attempt', {
      sort: {field: "id", order: "ASC"}, pagination: { page: 1, perPage }, filter
    });
  }

  const handleExport = async () => {
    const dataProvider = await defaultDataProvider();
    const {total} = await getAttempts(dataProvider, 1);
    const {data} = await getAttempts(dataProvider, total);
    const finalAttempts = _.map(data, a => {
      let att = _.pick(a, ['id', 'werifyPointId', 'ruleName', 'finishedAt', 'storedValues']);
      att.storedValues = parseStoredValue(att);
      return att;
    });
    
    // Convert data to CSV
    const csvContent = convertToCSV(finalAttempts);

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create an object URL and trigger a download
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'export.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (arr) => {
    const header = Object.keys(arr[0]).join(',');
    const values = arr.map(obj => Object.values(obj).join(',')).join('\n');
    return `${header}\n${values}`;
  };

  const parseStoredValue = record => {
    try {
      const storedJson = JSON.parse(record.storedValues);
      const condition = _.find(storedJson, c => c.pointer === storedValue);
      return condition.value;
    } catch {
      return;
    }
  }

  return <WerifyLayout>
    <Container maxWidth="md" id="lottery">
      <Box textAlign="center" width="100%" height="auto" margin="2em 0 0 0">
        <Form onSubmit={handleSubmit} noValidate id="lottery-form">
          <ReferenceInput source="werifyPointId" reference="WerifyPoint" filter={{archivedAtIsSet: false}} >
            <AutocompleteInput
              label="lottery.choose_werify_point"
              helperText={false}
              optionText={(r) => `${r.id} - ${r.name}` }
              validate={required()}
            />
          </ReferenceInput>
          <TextInput label="lottery.choose_stored_value" source="storedValue" fullWidth validate={required()} />
          <Button color="inverted" fullWidth variant="contained" type="submit" id="lottery-submit">
              {translate("lottery.see_attempts")}
          </Button>
        </Form> 
      </Box>
      { !_.isEmpty(filter) &&
        <List
          resource="Attempt"
          empty={false}
          actions={<TopToolbar><Button startIcon={<FileDownload />} onClick={handleExport} variant="contained" color="inverted" id="lottery-export">{translate("lottery.export")}</Button></TopToolbar>}
          pagination={<Pagination rowsPerPageOptions={[50]} />}
          perPage={50}
          sort={{field: "id", order: "ASC"}}
          filter={filter}
        >
          <Datagrid bulkActionButtons={false} id="lottery-list-attempts">
            <TextField source="id" sortable={false} />
            <ReferenceField source="werifyPointId" reference="WerifyPoint" link="show" sortable={false} >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="ruleId" reference="Rule" link="show" sortable={false} >
              <TextField source="name" />
            </ReferenceField>
            <DateField source="finishedAt" showTime={true} showDate={true} sortable={false} />
            <FunctionField source="storedValues" render={record => parseStoredValue(record) } sortable={false} />
          </Datagrid>
        </List>
      }
    
    </Container>
  </WerifyLayout>
}

export default Lottery;